import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BasicTypes } from '@/utils/types/BasicTypes';

export default class RestRepository {
  protected baseUrl!: string;

  protected headers!: AxiosRequestConfig['headers'];

  private axiosInstance: AxiosInstance;

  constructor(baseUrl?: string, headers?: AxiosRequestConfig['headers']) {
    this.baseUrl = baseUrl || '';
    this.headers = headers;
    this.axiosInstance = axios.create();
  }

  async get<T = object>({
    url,
    path,
    params,
    headers,
  }: {
     url?: string;
     path?: string;
     params?: AxiosRequestConfig['params'];
     headers?: AxiosRequestConfig['headers'];
  }): Promise<T> {
    const config: AxiosRequestConfig = headers ? { headers, params } : { headers: this.headers, params };
    const fullUrl = url ? `${url}${path}` : `${this.baseUrl}${path}`;
    const response: AxiosResponse<T> = await this.axiosInstance.get(fullUrl, config);
    return response.data;
  }

  async post<T = object>({
    url,
    path,
    data,
    headers,
    responseType,
  }: {
    url?: string;
    path?: string;
    data?: BasicTypes;
    headers?: AxiosRequestConfig['headers'];
    responseType?: string;
  }): Promise<T> {
    const config: AxiosRequestConfig = headers ? { headers } : { headers: this.headers };
    if (responseType) {
      Object.assign(config, { responseType });
    }
    const fullUrl = url ? `${url}${path || ''}` : `${this.baseUrl}${path || ''}`;
    const response: AxiosResponse<T> = await this.axiosInstance.post(fullUrl, data, config);
    return response.data;
  }

  async put<T = object>({
    url,
    path,
    putData,
    headers,
  }: {
    url?: string;
    path?: string;
    putData?: BasicTypes;
    headers?: AxiosRequestConfig['headers'];
  }): Promise<T> {
    const config: AxiosRequestConfig = headers ? { headers } : { headers: this.headers };
    const fullUrl = url ? `${url}${path}` : `${this.baseUrl}${path}`;
    const response: AxiosResponse<T> = await this.axiosInstance.put(fullUrl, putData, config);
    return response.data;
  }

  async delete({
    url,
    path,
    headers,
  }: {
    url?: string;
    path?: string;
    headers?: AxiosRequestConfig['headers'];
  }): Promise<void> {
    const config: AxiosRequestConfig = headers ? { headers } : { headers: this.headers };
    const fullUrl = url ? `${url}${path}` : `${this.baseUrl}${path}`;
    await this.axiosInstance.delete(fullUrl, config);
  }
}
